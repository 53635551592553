const DOMAIN = 'http://m.rosalinda.cl/'
const API = process.env.NODE_ENV === 'production' ? 'https://api.eflores.cl' : 'https://beta.eflores.cl'
/* new */
const VERSION = '/red/v1'
export const GENERATE_SII = `https://beta.eflores.cl/api/v2/generate-sii/`
export const GENERATE_SII_SUSCRIPTION = `https://beta.eflores.cl/api/v2/generate-sii-suscription/`
export const LOGIN = `${API}${VERSION}/login`
export const UPDATE_HOME = `https://beta.eflores.cl/cron/v1/create-json-inicio`
// products
export const GET_PRODUCTS_ALL = `${API}${VERSION}/products`
export const GET_PRODUCT = `${API}${VERSION}/product`
export const GET_PRODUCT_ACTIVITIES = `${API}${VERSION}/product-activities`
export const GET_ORDER_REMEMBER = `${API}${VERSION}/pedidos-impagos`
export const GET_PRODUCT_ADD = `${API}${VERSION}/products-add`
export const GET_ORDER_N = `${API}${VERSION}/get-orders`
export const GET_DASHBOARD = `${API}${VERSION}/dashboard`
export const MESSAGEBIRD = `${API}${VERSION}/message-bird`
export const ORDER_NAV = `${API}${VERSION}/orders-nav`
export const GENERAL_CONFIG = `${API}${VERSION}/general-config`
export const SEARCH_PRODUCTS = `${API}${VERSION}/search-products`
export const ADD_PRODUCT_NEW = `${API}${VERSION}/product/new`
export const GET_ZONES = `${API}${VERSION}/zones`
export const ORDER = `${API}${VERSION}/orders`
export const ORDERS_MAP = `${API}${VERSION}/orders-map`
export const DEALER_ROUTE = `${API}${VERSION}/dealer-route`
export const SEND_ORDERS_MAP = `${API}${VERSION}/orders-map/send-route`
export const GET_ORDER_DOCUMENT = `${API}${VERSION}/orders-document`
export const GET_STATUS_STORE = `${API}${VERSION}/status-my-store`
export const GET_SEARCH_SCHEDULE = `${API}${VERSION}/search-schedule`
export const GET_SEARCH_PRODUCTS = `${API}${VERSION}/searchs-products`
export const GET_SEARCH_STORE_AVAILABLE = `${API}${VERSION}/searchs-store-available`
export const GET_SEARCH_MESSAGES = `${API}${VERSION}/searchs-messages`
export const GET_SEARCH_BUYER = `${API}${VERSION}/searchs-buyer`
export const POST_FINISH_ORDER_STORE = `${API}${VERSION}/finish-order-store`
export const GET_ORDER_EMAIL = `${API}${VERSION}/order-email`
export const GET_SCHEDULE_STORE = `${API}${VERSION}/schedule-store`
export const GET_CASH_USER = `${API}${VERSION}/cash-user`
export const GET_CASH_BANK = `${API}${VERSION}/cash-bank`
export const MOVEMENT_CASH = `${API}${VERSION}/movement-cash`
export const CLIENT = `${API}${VERSION}/client`
export const ORDERS_PRINTER = `${API}${VERSION}/orders-printers`
export const COUPON_GENERATE = `${API}${VERSION}/coupon-express`
export const COUPON = `${API}${VERSION}/coupon`
export const CONFIG = `${API}${VERSION}/settings`
export const MASSIVE_ORDER = `${API}${VERSION}/settings/massive-orders`
export const ENVIOS_MASIVOS = `${API}${VERSION}/settings/envios-masivos`
export const SEND_WSP = `${API}${VERSION}/settings/send-wsp-special`
export const WSP_MSJ = `${API}${VERSION}/settings/wsp-mensajes`
export const GET_FEATURES = `${API}${VERSION}/features`
export const GET_PRODUCT_GOOGLE = `${API}${VERSION}/google-merchant`
export const GET_PRODUCT_GOOGLE_FIXED = `${API}${VERSION}/google-merchant-fix`
export const GET_FEATURES_VALUES = `${API}${VERSION}/features-values`
export const GET_SUSCRIPTIONS = `${API}${VERSION}/suscriptions`
export const GET_ATRIBUTES = `${API}${VERSION}/attributes`
export const GET_PRODUCTS_ALL_MIN = `${API}${VERSION}/products-all`
export const GET_INSUMOS = `${API}${VERSION}/insumos`
export const MI_STORE = `${API}${VERSION}/store`
export const INVENTORIES = `${API}${VERSION}/inventories`
export const INVENTORY_ACTIVE = `${API}${VERSION}/inventory-active`
export const GET_DASHBOARD_TALLER = `${API}${VERSION}/dashboard/taller`
export const TO_POST_HOME = `${API}${VERSION}/to-post-home`
export const TO_POST_ORDER = `${API}${VERSION}/to-post-order`
export const GET_PRODUCT_COLOR = `${API}${VERSION}/attributes-types`
export const GET_POLLS = `${API}${VERSION}/polls`
export const GET_BLOG = `${API}${VERSION}/blog`
export const GET_BLOG_CATEGORIES = `${API}${VERSION}/blog-categories`
export const GET_BLOG_TAGS = `${API}${VERSION}/blog-tags`
export const GET_DATE_IMPORTANT = `${API}${VERSION}/send-date-importants`
export const GET_STORES = `${API}${VERSION}/stores`
export const GET_SHOPS = `${API}${VERSION}/shops`
export const GET_GEOLOCATION = `${API}${VERSION}/geolocations`
export const GET_FILES = `${API}${VERSION}/files-bucket`
export const GET_ITEMS_DISCOUNT = `${API}${VERSION}/items-discount`
export const GET_COLORS_CAJA = `${API}${VERSION}/colors-cajas`
export const GET_COLORS_CAJA_GENERAL = `${API}${VERSION}/colors-cajas-general`
export const PUT_REFUND_FPAY = `${API}${VERSION}/orders`
export const GET_TAGS = `${API}${VERSION}/tags`
export const GET_IA = `${API}${VERSION}/ia`
/* new */

// old
export const GET_API = `${DOMAIN}apiStore.php`
export const GET_CONFIG = `${DOMAIN}apiStore.php`
export const GET_ORDERS = `${DOMAIN}apiStore.php`
export const GET_ORDER = `${DOMAIN}apiStore.php`
export const GET_WHATSAPP = `${DOMAIN}apiStore.php`
export const GETINSUMOS = `${DOMAIN}apiStore.php`
export const GET_INVENTORIES = `${DOMAIN}apiStore.php`

export const PRODUCTOS = `${DOMAIN}api/store/index.php`
export const OPENSALE = `${DOMAIN}api/store/index.php`
export const OUTSALE = `${DOMAIN}api/store/index.php`
export const GETPRODUCTS = `${DOMAIN}api/store/index.php`
export const CREATESALE = `${DOMAIN}api/store/index.php`
export const GETSALES = `${DOMAIN}api/store/index.php`
export const CLOSECHECKOUT = `${DOMAIN}api/store/index.php`
export const GETSALESPRODUCTS = `${DOMAIN}api/store/index.php`
export const GETCHECKOUTS = `${DOMAIN}api/store/index.php`
export const SEARCHCOMPANY = `${DOMAIN}api/store/index.php`
export const SEARCHCLIENT = `${DOMAIN}api/store/index.php`
export const SEARCHHORARIO = `${DOMAIN}api/store/index.php`
export const SEARCHSHOP = `${DOMAIN}api/store/index.php`
export const UPDATECLIENT = `${DOMAIN}api/store/index.php`
export const SEARCHTYPEADDRESS = `${DOMAIN}api/store/index.php`
export const NEWADDRESS = `${DOMAIN}api/store/index.php`
export const GETPRODUCTSSTOCK = `${DOMAIN}api/store/index.php`
export const GETBANNER = `${DOMAIN}api/store/index.php`
export const NEWBANNER = `${DOMAIN}api/store/index.php`
export const DELETEBANNER = `${DOMAIN}api/store/index.php`
export const GETSLIDE = `${DOMAIN}api/store/index.php`
export const NEWSLIDE = `${DOMAIN}api/store/index.php`
export const DELETESLIDE = `${DOMAIN}api/store/index.php`
export const SEARCHPRODUCT = `${DOMAIN}api/store/index.php`
export const GETPRODUCTSADD = `${DOMAIN}api/store/index.php`
export const LASTCHECKOUT = `${DOMAIN}api/store/index.php`
export const NEWCLIENT = `${DOMAIN}api/store/index.php`
export const ADDPRODUCTS = `${DOMAIN}api/store/index.php`
export const UPDATEPRODUCTSTOCK = `${DOMAIN}api/store/index.php`
export const GETINFOHISTORY = `${DOMAIN}api/store/index.php`
export const GETPRODUCTINFO = `${DOMAIN}api/store/index.php`
export const REPORTEXCEL = `${DOMAIN}api/store/index.php`
export const GETORDERSRETIROENLOCAL = `${DOMAIN}api/store/index.php`
export const SEARCHINSUMO = `${DOMAIN}api/store/index.php`
export const GETLISTPRODUCTTRANS = `${DOMAIN}api/store/index.php`

export const GETREPORTEXCEL = `${DOMAIN}api/store/index.php`
export const GENERATEXLS = `${DOMAIN}api/store/index.php`
export const GETSTORES = `${DOMAIN}api/store/index.php`
